var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"98%","icon":"mdi-cellphone-dock","color":"primary","title":_vm.$t(_vm.entity + '.TITLE'),"text":_vm.$t(_vm.entity + '.SUBTITLE')}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Filtrar por nombre: "+_vm._s(_vm.search))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","append-icon":"search","placeholder":"Escribe el nombre de la etiqueta","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NEW_ITEM")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-container',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Nombre")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Nombre de etiqueta todofull"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-divider',{staticClass:"my-3",staticStyle:{"border":"1px solid #ddd"}}),_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Etiquetas relacionadas ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Messenger")]),_c('v-combobox',{attrs:{"item-text":"nameWithFanpage","items":_vm.messengerTags,"multiple":"","chips":"","outlined":"","no-data-text":"No se encontraron etiquetas","label":"Busca las etiquetas"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var attrs = ref.attrs;
                                var item = ref.item;
                                var select = ref.select;
                                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"deep-purple accent-4","outlined":""},on:{"click":select,"click:close":function($event){return _vm.remove(item._id, _vm.editedItem.messengerTags)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.nameWithFanpage))])])]}}],null,true),model:{value:(_vm.editedItem.messengerTags),callback:function ($$v) {_vm.$set(_vm.editedItem, "messengerTags", $$v)},expression:"editedItem.messengerTags"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Página Web")]),_c('v-combobox',{attrs:{"item-text":"name","items":_vm.webTags,"multiple":"","chips":"","outlined":"","no-data-text":"No se encontraron etiquetas","label":"Busca las etiquetas"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var attrs = ref.attrs;
                                var item = ref.item;
                                var select = ref.select;
                                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"deep-purple accent-4","outlined":""},on:{"click":select,"click:close":function($event){return _vm.remove(item._id, _vm.editedItem.webTags)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(_vm.editedItem.webTags),callback:function ($$v) {_vm.$set(_vm.editedItem, "webTags", $$v)},expression:"editedItem.webTags"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Atributos")]),_c('v-combobox',{attrs:{"item-text":"name","items":_vm.attributeTags,"multiple":"","chips":"","outlined":"","no-data-text":"No se encontraron etiquetas","label":"Busca las etiquetas"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var attrs = ref.attrs;
                                var item = ref.item;
                                var select = ref.select;
                                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"deep-purple accent-4","outlined":""},on:{"click":select,"click:close":function($event){return _vm.remove(item._id, _vm.editedItem.attributeTags)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(_vm.editedItem.attributeTags),callback:function ($$v) {_vm.$set(_vm.editedItem, "attributeTags", $$v)},expression:"editedItem.attributeTags"}})],1)],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Guardar")])],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])])],1)],1)]},proxy:true},{key:"item.is_active",fn:function(ref){
                                var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.is_active},on:{"change":function($event){return _vm.updateIsActive(item, $event)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.action",fn:function(ref){
                                var item = ref.item;
return [(_vm.rolPermisos['Edit'])?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.rolPermisos['Delete'])?_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NO_DATA")))])]},proxy:true},{key:"item.description",fn:function(ref){
                                var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.updatedAt",fn:function(ref){
                                var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.updatedAt)))]}},{key:"item.url",fn:function(ref){
                                var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-btn',{attrs:{"color":"primary","small":""}},[_vm._v("Visitar")])],1)]}},{key:"item.attributes",fn:function(ref){
                                var item = ref.item;
return _vm._l((item.attributes),function(attribute,attIndex){return _c('ul',{key:attIndex},[_c('li',[_c('b',[_vm._v(_vm._s(attribute.name)+": ")]),_vm._v(_vm._s(attribute.options.join(","))+" ")])])})}},{key:"item.categories",fn:function(ref){
                                var item = ref.item;
return _vm._l((item.categories),function(category,cattIndex){return _c('ul',{key:cattIndex},[_c('li',[_vm._v(_vm._s(category.name))])])})}},{key:"item.status",fn:function(ref){
                                var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }